import Home from "./pages/Home";
import Results from "./pages/Results";
import LoginPage from "./pages/LoginPage";
import Logout from './pages/LogoutPage'; // Adjust the path as necessary
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./util/NavigationClient";
import { AuthProvider, useAuthStatus } from "./util/authContext";

// InnerApp component
const InnerApp = ({ pca }: { pca: PublicClientApplication }) => {
  const navigate = useNavigate();
  const { isAuthenticated, setAuthStatus } = useAuthStatus(); // Used within the scope of AuthProvider

  useEffect(() => {
    pca.handleRedirectPromise()
      .then((res) => {
        if (res !== null) {
          localStorage.setItem('isAuthenticated', 'true');
          setAuthStatus(true); // Update the authentication status
          navigate('/'); // Navigate to the home page
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pca, setAuthStatus, navigate]);

  return (
    <Routes>
      <Route 
        path="/" 
        element={isAuthenticated ? <Home pca={pca}/> : <LoginPage />} 
      />
      <Route path="/results" element={<Results pca={pca}/>} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

// App component
const App = ({ pca }: { pca: PublicClientApplication }) => {
  const navigationClient = new CustomNavigationClient(useNavigate());
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <AuthProvider pca={pca}>
        <InnerApp pca={pca} />
      </AuthProvider>
    </MsalProvider>
  );
};


export default App;
