import { useState, useEffect, FC } from "react";

type HeaderProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

export const Header: FC<HeaderProps> = ({isVisible, setIsVisible}) => {
  const [shouldRender, setShouldRender] = useState(true);

  const toggleVisibility = () => {
    // Start the hiding process
    setIsVisible(false);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!isVisible) {
      // Wait for the transition to finish before hiding the element
      timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 150); // Match the timeout to the duration of the transition
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  // Add 'hidden' class to actually remove the element from the layout after the transition
  let divClasses = `transition-opacity duration-150 ease-out ${
    isVisible ? "opacity-100" : "opacity-0 scale-95"
  }`;

  return (
    <>
      {shouldRender && (
        <div
          className={divClasses}
          style={{ transitionProperty: "opacity, transform" }}
        >
          <div className="mx-auto max-w-2xl lg:text-center border-b pb-12 border-gray-900/10">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Embedding Insights
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our goal is to improve the quality and accuracy of search results.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Use the form below to ask a question against a document and rate
              the results. Please try to make sure your question is formed as a
              question and not a statement. For example, "What design factor do
              I need to apply to a production casing string?" is a good
              question, but "casing string df" is not a good question.
            </p>
            <div className="flex justify-center items-center mt-8">
              <button
                onClick={toggleVisibility}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
