import axios from "axios";
import React, { useEffect, useState } from "react";
import type { StrategyType } from "../Strategy/Strategy.type";
import { PublicClientApplication } from "@azure/msal-browser";
const clientId = import.meta.env.VITE_CLIENT_ID;

interface SourceSectionProps {
  strategy: StrategyType;
  pca: PublicClientApplication;
}

export const SourceSection: React.FC<SourceSectionProps> = ({
  strategy,
  pca,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!strategy.responseId) return; // Exit if strategy.responseId does not exist

      const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
      const tokenResponse = await pca.acquireTokenSilent({
        account: pca.getAllAccounts()[0],
        scopes: [`api://${clientId}/Strategy.Use`],
      });

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenResponse.accessToken}`,
      };

      if (strategy.parsedInfo) {
        const promises = strategy.parsedInfo.map(async (source) => {
          const response = await axios.post(
            `${apiEndpoint}/api/insertCitation`,
            {
              CitationTitle: source.documentTitle,
              CitationContent: source.responseText,
              CitationURL: source.documentSourceUrl,
              ResponseId: strategy.responseId,
            },
            { headers: headers }
          );
          return response.data;
        });

        //@ts-ignore
        const _results = await Promise.all(promises);
      }
    };

    fetchData().catch(console.error);
  }, [strategy, pca]);

  return (
    <>
      <div className="max-width pt-2.5 mx-2">
        {strategy.parsedInfo && (
          <button
            onClick={() => setIsVisible(!isVisible)}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            Source
          </button>
        )}
        {isVisible && (
          <div
            className="mt-4 p-4 border transition-opacity ease-in-out duration-300"
            style={{
              opacity: isVisible ? 1 : 0,
              visibility: isVisible ? "visible" : "hidden",
            }}
          >
            {strategy.parsedInfo && (
              <>
                {strategy.parsedInfo.map((source) => (
                  <div
                    className="flex items-center gap-x-1 text-xs border-gray-200"
                    key={`${source.documentTitle}-${source.responseText}`}
                  >
                    <p className="relative z-10 bg-gray-50 px-2.5 py-1.5 font-normal text-gray-600 hover:bg-gray-50 mt-0">
                      {source?.documentSourceUrl ? (
                        <span className="text-blue-500 hover:underline focus:outline-none">
                          <a
                            href={source?.documentSourceUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {source?.documentTitle}
                          </a>
                        </span>
                      ) : (
                        <span className="text-gray-600 focus:outline-none">
                          Unavailable
                        </span>
                      )}
                      <br />
                      {source.documentTitle
                        ? source.location
                        : `Location: ${
                            source.location ? source.location : "Unavailable"
                          }`}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

// export default SourceSection;
