import React, { createContext, useContext, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser"; // Import this

type AuthContextType = {
  isAuthenticated: boolean;
  setAuthStatus: (status: boolean) => void;
  logout: () => void; // Add logout method
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Add a prop for the PCA instance
type AuthProviderProps = {
  children: React.ReactNode;
  pca: PublicClientApplication; // Add this
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, pca }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  const logout = () => {
    // Clear local authentication state
    localStorage.removeItem("isAuthenticated");
    setIsAuthenticated(false);

    // Sign out from Azure AD
    pca.logoutRedirect({
      postLogoutRedirectUri: "/" // Redirect to home page after logout
    });
  };

  const setAuthStatus = (status: boolean) => {
    localStorage.setItem("isAuthenticated", status.toString());
    setIsAuthenticated(status);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthStatus, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthStatus = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthStatus must be used within an AuthProvider");
  }
  return context;
};
