import React from "react";
import StrategyItem from "./StrategyItem";
import type { StrategyType } from "./Strategy.type";
import { PublicClientApplication } from "@azure/msal-browser";

// const [getRating, setRating] = useState(false);

interface StrategiesProps {
  strategies: StrategyType[];
  pca: PublicClientApplication;
  resetForm: () => void;
}

const Strategies: React.FC<StrategiesProps> = ({ strategies, pca, resetForm }) => {

  window.addEventListener("beforeunload", (event) => {
    if (strategies.some((strategy) => strategy.loading)) {
      event.preventDefault();
      event.returnValue =
        "Warning: Data will be lost if you leave this page without completing all items!";
    }
  });

  return (
    <>
      <div className="bg-white py-24 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-2 border-gray-200 pt-12 pb-12">
            {strategies.map((strategy) => (
              <StrategyItem
                key={strategy.category}
                strategy={strategy}
                pca={pca}
              />
            ))}
          </div>
        </div>
        <div className="relative max-w-full">
          <div className="pb-2.5 absolute inset-y-0 -mr-10 mt-4 right-0 w-64">
            <span className="inline-block align-bottom">
              <span> </span>
              <button
                type="button"
                onClick={resetForm}
                className="justify-self-center mt-3 rounded-md bg-indigo-600 mx-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-10"
              >
                Reset
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export type { StrategyType };
export default Strategies;
