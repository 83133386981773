import axios, { AxiosResponse } from "axios";
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

/**
 * Type definition for body parameter in API calls.
 */
export interface BodyType {
  [key: string]: any; // Consider using a more specific type if possible.
}

/**
 * Makes an API call to insert data, either through a GET or POST request.
 *
 * @param method - The HTTP method to use: "get" or "post".
 * @param endpoint - The API endpoint to call.
 * @param body - The body of the request, for POST requests.
 * @param accessToken - Access Token used for authentication.
 * @returns The data from the response of the API call.
 */
// Replace 'any' with a generic type for better type checking.
export default async function makeAPICall<T>(
  method: "get" | "post",
  endpoint: string,
  body: BodyType | null,
  accessToken: string
): Promise<T> {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  // Initialize the response with a type assertion
  let response: AxiosResponse<T>;

  if (method === "get") {
    response = await axios.get<T>(`${apiEndpoint}/api/${endpoint}`, {
      headers: headers,
      params: body,
    });
  } else if (method === "post") {
    // Ensure body is not null for a POST request
    if (body === null) {
      throw new Error("The body cannot be null for a POST request");
    }
    response = await axios.post<T>(`${apiEndpoint}/api/${endpoint}`, body, {
      headers,
    });
  } else {
    // Throw an error if the method is neither 'get' nor 'post'
    throw new Error(`The HTTP method "${method}" is not supported`);
  }

  return response.data;
}
