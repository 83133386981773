// PcaContext.tsx
import React, { createContext } from 'react';
import { msalConfig } from "../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

export const PcaContext = createContext<PublicClientApplication | undefined>(undefined);

// AuthContext.tsx

interface AuthContextProps {
  children: React.ReactNode;
}

const AuthContext: React.FC<AuthContextProps> = ({ children }) => {
  const pca = new PublicClientApplication(msalConfig);

  return (
    <PcaContext.Provider value={pca}>
      {children}
    </PcaContext.Provider>
  );
}

export default AuthContext;
