import { useEffect } from 'react';
import { useAuthStatus } from '../util/authContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuthStatus();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/'); // Redirect to home page or login page after logout
  }, [logout, navigate]);

  return (
    <div>Logging out...</div> // You can have a loading spinner here
  );
}

export default Logout;
