import React, { useState, useEffect, FC } from "react";

type QuestionInputProps = {
  onSubmit: (question: string) => void;
  submitDisabled?: boolean;
  inputHidden?: boolean;
};

export const QuestionInput: FC<QuestionInputProps> = ({
  onSubmit,
  submitDisabled,
  inputHidden,
}) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [question, setQuestion] = useState("");

  // Handle the form submission
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission
    onSubmit(question);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!inputHidden) {
      // Wait for the transition to finish before hiding the element
      timeoutId = setTimeout(() => {
        setShouldRender(true);
      }, 150); // Match the timeout to the duration of the transition
    }
    return () => clearTimeout(timeoutId);
  }, [inputHidden]);

  // Add 'hidden' class to actually remove the element from the layout after the transition
  let divClasses = `transition-opacity duration-150 ease-in ${
    !inputHidden ? "opacity-100" : "opacity-0 scale-95"
  }`;

  return (
    <>
      {shouldRender && (
        <div
          className={divClasses}
          style={{ transitionProperty: "opacity, transform" }}
        >
          <div className="mx-auto max-w-7xl px-2 lg:px-2">
            <div className="mx-auto max-w-2xl lg:text-center">
              <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                  <div className="text-center mt-10">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="question"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Question
                      </label>
                      <div className="mt-2">
                        <div className="mx-auto flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                          <input
                            type="text"
                            name="question"
                            id="question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 sm:max-w-md rounded-md"
                            placeholder="How often do I need to test seals?"
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                {question.length > 0 && (
                  <div className="flex justify-center items-center mt-4">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={submitDisabled}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
