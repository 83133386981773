import { Configuration, RedirectRequest, BrowserCacheLocation } from "@azure/msal-browser";

const clientId = import.meta.env.VITE_CLIENT_ID;
const tenantId = import.meta.env.VITE_TENANT_ID;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // This can also be CacheLocation.LocalStorage
        storeAuthStateInCookie: false, // Set this to true if you have issues on Safari or IE
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [`api://${clientId}/.default profile openid email https://graph.microsoft.com/User.Read`]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};