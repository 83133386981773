import { useState, useEffect } from "react";

import Strategies from "../components/Strategy/Strategies";
import type { StrategyType } from "../components/Strategy/Strategies";
import axios from "axios";
import NavBar, {NotificationItem} from "../components/NavBar/NavBar";
import { Header } from "../components/Header/Header";
import { QuestionInput } from "../components/QuestionInput/QuestionInput";
import { PublicClientApplication } from "@azure/msal-browser";
const clientId = import.meta.env.VITE_CLIENT_ID;
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

import { getEncoding } from "js-tiktoken"; 
const encoding = getEncoding("cl100k_base"); // Assuming the Tokenizer class from js-tiktoken is used here

// type LoadingState = { [key: string]: boolean };

function Home({ pca }: { pca: PublicClientApplication }) {
  const [headerVisible, setHeaderVisible] = useState(true);
  const [questionInputHidden, setquestionInputHidden] = useState(true);
  const [strategies, setStrategies] = useState<StrategyType[]>([]);
  const [_, setReset] = useState(false);

  useEffect(() => {
    if (!headerVisible) {
      setquestionInputHidden(false);
    }
  }, [headerVisible]);

  interface BodyType {
    [key: string]: any;
  }

  function resetForm() { 
    setReset(true);
    setquestionInputHidden(false);
    setHeaderVisible(false);
    setStrategies([]);
    setReset(false);
  }

  async function makeInsertAPICall(endpoint: string, body: BodyType) {
    const tokenResponse = await pca.acquireTokenSilent({
      account: pca.getAllAccounts()[0],
      scopes: [`api://${clientId}/Strategy.Use`],
    });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenResponse.accessToken}`,
    };

    let response = await axios.post(`${apiEndpoint}/api/${endpoint}`, body, {
      headers: headers,
    });

    return response.data;
  }

  async function submitQuestion(inputQuestion: string) {
    // Assume pca, apiEndpoint, clientId are available in your component's scope
    setquestionInputHidden(true);

    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          account: accounts[0], // Select the first account
          scopes: [`api://${clientId}/Strategy.Use`],
        });

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenResponse.accessToken}`,
        };

        let categoriesResponse = await axios.get(
          `${apiEndpoint}/api/categories`,
          {
            headers: headers,
          }
        );
        let categories: string[] = categoriesResponse.data["categories"];

        let timeGenerated = new Date().toISOString();

        let promptIdResponse: any;
        try {
          promptIdResponse = await makeInsertAPICall("insertPrompt", {
            PromptText: inputQuestion,
            TimeGenerated: timeGenerated,
            Username: tokenResponse.account?.username || "",
          });
        } catch (error) {
          console.error("Error inserting prompt:", error);
          return;
        }

        let promptId: number;
        promptId = parseInt(promptIdResponse["PromptId"]);
        // console.log("Prompt ID:", promptId);

        let searchQuery = await axios.post(
          `${apiEndpoint}/api/generateSearchQuery`,
          { question: inputQuestion, category: categories[0] },
          { headers: headers }
        );

        let searchQueryTokens = encoding.encode((searchQuery.data["query"] as string)).length;
        let promptTokens = encoding.encode((inputQuestion as string)).length;

        await makeInsertAPICall(
          "insertPromptMetadata",
          {
            PromptId: promptId,
            SearchQuery: searchQuery.data["query"],
            SearchQueryTokens: searchQueryTokens,
            PromptTokens: promptTokens
          }
        );

        // Set initial strategies with a loading state
        let initialStrategies: StrategyType[] = categories.map((category) => ({
          responseId: 0,
          strategy_name: category,
          index_name: "embeddings_test", // assuming a default value
          category: category,
          question: searchQuery.data["query"],
          userQuestion: inputQuestion,
          username: tokenResponse.account?.username || "", // Handle the case when username is undefined
          prompt_id: promptId, // assuming a default or placeholder value
          loading: true,
        }));

        setStrategies(initialStrategies);

        categories.forEach(async (category, index) => {
          let url = `${apiEndpoint}/api/strategy`;

          try {
            const strategyResponse = await axios.post(
              url,
              {
                strategy_name: category,
                index_name: "embeddings_test",
                category: category,
                userQuestion: inputQuestion,
                question: searchQuery.data["query"],
                username: tokenResponse.account?.username,
                llm_model: "gpt432k",
                llm_temp: 0.1,
                question_timestamp: timeGenerated,
                prompt_id: promptId,
              },
              { headers: headers }
            );

            // Update the corresponding strategy with the response data and set loading to false
            setStrategies((prevStrategies) =>
              prevStrategies.map((strat, stratIndex) =>
                stratIndex === index
                  ? { ...strategyResponse.data, loading: false }
                  : strat
              )
            );
          } catch (error) {
            // Handle the error and update the strategy's loading state
            setStrategies((prevStrategies) =>
              prevStrategies.map((strat, stratIndex) =>
                stratIndex === index ? { ...strat, loading: false } : strat
              )
            );
            console.error("Error loading strategy:", category, error);
          }
        });
      } catch (error) {
        // Handle token acquisition errors
        console.error("Token acquisition error:", error);
        // TODO: Consider falling back to an interactive method or reauthenticating the user
          // pca.loginRedirect({
          //   scopes: ["openid", "profile"],
          // });
      }
    } else {
      // TODO: Handle the case where there are no accounts - likely the user is not logged in
      // Redirect to login or show a relevant message
      // pca.loginRedirect({
      //   scopes: ["openid", "profile"],
      // });
    }
  }

  const navigation = [
    { name: "Test", href: "/", current: true },
    { name: "Results", href: "/results", current: false },
  ];

  const notifications: NotificationItem[] = [{name: "No notifications yet.", href:"#"}]
  
  return (
    <>
      <NavBar pca={pca} navigation={navigation} notifications={notifications}/>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-2 lg:px-2">
          <div key="Header">
            <Header isVisible={headerVisible} setIsVisible={setHeaderVisible} />
          </div>
          <div key="QuestionInput">
            <QuestionInput
              onSubmit={submitQuestion}
              submitDisabled={strategies.some((strategy) => strategy.loading)} // Disabled if any strategy is still loading
              inputHidden={questionInputHidden}
            />
          </div>
          <div
            key="Strategies"
            className={`${
              questionInputHidden ? "absolute mx-auto top-20 w-7xl" : "mt-4"
            }`}
          >
            {/* Instead of a single isLoading, check if any strategy is loading */}
            {strategies.some((strategy) => strategy.loading) && (
              <div className={`flex justify-center items-center top-auto`}>
                <span className="text-center inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 ">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </span>
              </div>
            )}
            {strategies.length > 0 && (
              <Strategies strategies={strategies} pca={pca} resetForm={resetForm}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
