import { useState, useEffect } from "react";

import NavBar, {NotificationItem} from "../components/NavBar/NavBar";
import { Header } from "../components/Header/Header";

import makeAPICall from "../util/makeAPICall";

import { PublicClientApplication } from "@azure/msal-browser";
const clientId = import.meta.env.VITE_CLIENT_ID;
// const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

// Define the interfaces based on your database schema
interface Prompt {
  PromptID: number;
  PromptText: string;
  TimeGenerated: Date;
  Username: string;
}

interface PromptMetadata {
  MetaID: number;
  PromptId: number;
  SearchQuery: string;
}

interface Response {
  ResponseID: number;
  PromptID: number;
  ResponseText: string;
}

interface ResponseMetadata {
  MetadataID: number;
  ResponseID: number;
  TimeFromPrompt: number;
  Rating: number;
  TimeFromResponseToScore: number;
  StrategyID: number | null;
  RatingComment: string | null;
}

interface Strategy {
  StrategyID: number;
  StrategyName: string;
}

// Combined data type
export interface UserData {
  prompt: Prompt;
  promptMetadata: PromptMetadata;
  response: Response;
  responseMetadata: ResponseMetadata;
  strategy?: Strategy;
}

export interface UserPromptData {
  prompt: Prompt;
  promptMetadata: PromptMetadata;
}

function Results({ pca }: { pca: PublicClientApplication }) {
  const [_, setHeaderVisible] = useState(true);
  const [userData, setUserData] = useState<UserData[]>([]);

  useEffect(() => {
    async function getResults() {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          account: pca.getAllAccounts()[0],
          scopes: [`api://${clientId}/Strategy.Use`],
        });

        const data: UserData[] = await makeAPICall<UserData[]>(
          "get",
          "userData",
          { username: `${tokenResponse.account?.username}` },
          tokenResponse.accessToken
        );

        setUserData(data);
      } catch (error) {
        console.error("Error fetching results:", error);
        // Handle the error, e.g., show a message to the user
      }
    }

    getResults();
  }, [pca]); // Add pca to the dependency array if the token acquisition depends on it

  const navigation = [
    { name: "Test", href: "/", current: false },
    { name: "Results", href: "/Results", current: true },
  ];

  const notifications: NotificationItem[] = [{name: "No notifications yet.", href:"#"}]

  return (
    <>
      <NavBar pca={pca} navigation={navigation} notifications={notifications} />
      <Header isVisible={false} setIsVisible={setHeaderVisible} />
      <div className="container mx-8 mt-20 absolute inset-0">
        <div className="grid grid-cols-1 not-prose relative rounded-xl overflow-hidden bg-slate-300 dark:bg-slate-900">
          <table className="border-collapse table-auto w-full text-sm mt-4">
            <thead className="pt-10 overflow-hidden">
              <tr>
              <th className="border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left border-slate-100 dark:border-slate-700">Prompt</th>
              <th className="border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left border-slate-100 dark:border-slate-700">Answer</th>
              <th className="border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left border-slate-100 dark:border-slate-700">Rating</th>
              <th className="border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left border-slate-100 dark:border-slate-700">Date/Time</th>
              </tr>
            </thead>
            <tbody className="bg-slate-300 dark:bg-slate-600 text-slate-400 dark:text-slate-100 ">
              {userData &&
                userData.map((item: UserData) => (
                  
                  <tr key={`prompt_${item.promptMetadata.MetaID}`} className="">
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-400 dark:text-slate-100">{item.prompt.PromptText}</td>
                    <td>{item.response.ResponseText}</td>
                    <td className="text-center">{item.responseMetadata.Rating}</td>
                    <td>{new Date(item.prompt.TimeGenerated).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Results;
