import { FC } from "react";

type InfoIconProps = {
  message: string;
  className?: string;
};

export const InfoIcon: FC<InfoIconProps> = ({ message, className }) => {
  return (
    <span className={className ? className : ""}>
      <div className="relative group inline-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
        <div className="absolute top-0 left-0 w-64 mx-auto rounded-lg bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500 p-2 z-10 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300">
          <span className="">
            <div className="flex">
              <svg
                className="h-6 w-6 stroke-sky-500 group-hover:stroke-white"
                fill="none"
                viewBox="0 0 24 24"
              ></svg>
              {/* <h3 className="text-slate-900 group-hover:text-white text-sm font-semibold">
              Information
            </h3> */}
            </div>
            <p className="text-slate-500 group-hover:text-white text-sm text-center -mt-6">
              {message}
            </p>
          </span>
        </div>
      </div>
    </span>
  );
};
