import { useMsal } from "@azure/msal-react";

export default function LoginPage() {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <defs>
              <radialGradient
                id="blueGradient"
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%"
              >
                <stop offset="0%" stopColor="#add8e6" stopOpacity="1" />
                <stop offset="100%" stopColor="#00008b" stopOpacity="1" />
              </radialGradient>
            </defs>
            <circle cx="512" cy="512" r="512" fill="url(#blueGradient)" />
          </svg>

          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Embedding Insights
            </h2>
            <p className="mt-6 mb-6 text-lg leading-8 text-gray-300">
              Click the "Log In" button below to get started.
            </p>
            <div className="mt-10 mb-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                onClick={initializeSignIn}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm cursor-pointer hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Log In
              </a>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-20 w-[32rem] mr-2 max-w-none rounded-md"
              src="/dvn.svg"
              alt="Devon Energy Logo"
              // width={1824}
              // height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
