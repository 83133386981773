import axios from "axios";
import React, { useState, FC } from "react";
import styles from "./RatingSection.module.css";
import { InfoIcon } from "../InfoIcon/InfoIcon";
import { StrategyType } from "../Strategy/Strategy.type";
import { PublicClientApplication } from "@azure/msal-browser";

const clientId = import.meta.env.VITE_CLIENT_ID;

type StarRatingProps = {
  strategy: StrategyType;
  onRatingChange?: (key: string, rating: number) => void;
  pca: PublicClientApplication;
};

const StarRating: FC<StarRatingProps> = ({ strategy, onRatingChange, pca }) => {
  const [rating, setRating] = useState(0);
  const [metadataId, setMetadataId] = useState<number>();
  const [hover, setHover] = useState(0);
  const [comments, setComments] = useState("");

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(strategy.strategy_name, newRating);
    }
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComments(event.target.value);
  };

  const submitRating = async (
    strategy: StrategyType,
    rating: number,
    comments: string
  ) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const tokenResponse = await pca.acquireTokenSilent({
      account: pca.getAllAccounts()[0],
      scopes: [`api://${clientId}/Strategy.Use`],
    });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenResponse.accessToken}`,
    };

    let now = new Date();
    let answerTimestamp = strategy.answer_timestamp;

    let question_to_rating_time = 0;
    if (answerTimestamp !== undefined) {
      let date = new Date(answerTimestamp);
      question_to_rating_time = (now.getTime() - date.getTime()) / 1000;
    } else {
      // handle the case where answerTimestamp is undefined
      // for example, assign a default value
      let date = new Date();
      question_to_rating_time = (now.getTime() - date.getTime()) / 1000;
    }

    let metadataId = await axios.post(
      `${apiEndpoint}/api/insertResponseMetadata`,
      {
        StrategyName: strategy.strategy_name,
        ResponseId: strategy.responseId,
        Rating: rating,
        RatingComment: comments,
        TimeFromPrompt: strategy.question_to_answer_time,
        TimeFromResponseToScore: question_to_rating_time,
        ResponseModel: strategy.llm_model,
        Answer: strategy.answer,
      },
      {
        headers: headers,
      }
    );

    return metadataId.data;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let metaDataIdResponse = await submitRating(strategy, rating, comments);
    let metadataId: number;
    metadataId = parseInt(metaDataIdResponse["ResponseMetadataId"]);
    setMetadataId(metadataId);
    // console.log("Form Submitted! metaDataId: ", metadataId);

    return metadataId;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {metadataId !== undefined && (
          <span className="block text-sm font-semibold leading-6 text-gray-600 pt-2.5 pb-2">
            Rating Completed! Thank you for your feedback!
            <br />
            <br />
            <p className="font-normal">
              <strong>Rating:</strong> {rating} star{rating > 1 ? "s" : ""} <br />
              <strong>Comments:</strong> "{comments ? comments.trim() : "None"}"
            </p>
          </span>
        )}
        {metadataId === undefined && (
          <>
            <div className="flex mxmb-2.5">
              <label
                htmlFor="rating"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                <p className="pr-2">Rating</p>
              </label>
              <InfoIcon
                message={
                  "Please rate the quality of the answer. Pay attention to accuracy, completeness, and relevance."
                }
              />
            </div>

            <span className={styles["star-rating"]}>
              {[...Array(5)].map((_, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={
                      index <= (hover || rating) ? styles.on : styles.off
                    }
                    onClick={() => handleRatingChange(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                    disabled={metadataId !== undefined}
                  >
                    <span className={styles.star}>
                      {/* &#9733; */}
                      <svg width="18" height="24" fill="currentColor">
                        <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
                      </svg>
                    </span>
                  </button>
                );
              })}
            </span>
            {rating > 0 && (
              <div className="sm:col-span-2 mt-3">
                <label
                  htmlFor="comments"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Comments
                  <InfoIcon
                    className="mx-2 mt-2"
                    message={
                      "If you would like to add comments, please do so here."
                    }
                  />
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="comments"
                    id="comments"
                    rows={4}
                    onChange={handleCommentsChange}
                    disabled={metadataId !== undefined}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    placeholder={`Example: I chose to rate this answer ${rating} star${
                      rating > 1 ? "s" : ""
                    } because...`}
                  />
                </div>
              </div>
            )}
            {rating > 0 && (
              <div className="pb-2.5 inline-block align-bottom">
                <button
                  type="submit"
                  disabled={metadataId !== undefined}
                  className="justify-self-center mt-3 rounded-md bg-indigo-600 disabled:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
      </form>{" "}
    </>
  );
};

export default StarRating;
